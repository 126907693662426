<template>
    <el-collapse-item :name="itemName">
        <template slot="title">
            <div class="prtf-item-header" v-on:click.stop="">
                <div class="prtf-item-header-l">
                    <div class="prtf-item-header-name">Summary</div>

                    <transition name="el-fade-in">
                        <div class="prtf-summary-currency"  v-show="active">
                            <el-radio-group v-model="currency" @change="get_summary">
                                <el-radio-button
                                    v-for="cur in currency_list"
                                    :label="cur.code"
                                    :key="cur.code">
                                </el-radio-button>
                            </el-radio-group>
                        </div>
                    </transition>
                </div>
                
                <div class="prtf-item-header-r">
                    <transition name="el-fade-in">
                        <el-select v-model="mode" v-show="active"  @change="get_summary">
                            <template slot="prefix">Display:</template>
                            <el-option label="by assets" value="assets"></el-option>
                            <el-option label="by portfolios" value="portfolios"></el-option>
                        </el-select>
                    </transition>

                    <el-button v-if="active" class="prtf-item-header-arrow" @click="collapse_item">
                        <i class="el-icon-arrow-up"></i>
                    </el-button>
                    <el-button v-else class="prtf-item-header-arrow" @click="expand_item">
                        <i class="el-icon-arrow-down"></i>
                    </el-button>
                </div>
            </div>
        </template>

        <DataGrid
            :expandable="false"
            :showsold="false"
            summary
            :summary_mode="mode"
            :source_list="list"
            :source_loading="loading"
            :client="client" />
        
    </el-collapse-item>
</template>

<script>
import { mapState } from 'vuex'

import DataGrid from './Components/DataGrid'

export default {
    components: { DataGrid },
    props: {
        client: {
            required: true,
        },
        active: {
            type: Boolean,
        },
        itemName: {
            type: String,
            required:true,
        },
        portfolio_mode: {
            type: String,
            required:true,
        },
        portfolio_date: {
            type: Date,
            required:true,
        },
    },

    data(){
        return {
            mode     : 'assets',
            currency : 'USD',
            list     : [],
        }
    },

    computed: {
        ...mapState({
            user          : state => state.app.user,
            currency_list : state => state.currency.list,
            loading       : state => state.portfolio.summaryLoading,
        }),

        init_columns(){
            return this.initColumns
        }
    },

    methods: {
        collapse_item(){
            this.$emit('collapse-item', this.itemName)
        },
        expand_item(){
            this.$emit('expand-item', this.itemName)
        },
        get_summary(){
            this.$store.dispatch('portfolio/getSummaryPlain', {
                client_id    : this.client._id,
                currency     : this.currency,
                display_mode : this.mode,
                mode         : this.portfolio_mode,
                date         : this.portfolio_date,
            }).then((response) => {
                this.list = response
            });
        },
    },

    watch: {
        portfolio_mode(val){
            this.get_summary()
        },
        portfolio_date(val){
            this.get_summary()
        }
    },

    created(){
        this.get_summary();
    }
}
</script>
